<template>
  <div style="margin-left:350px;margin-top:10px;">
    <el-row style="marginleft: 350px; margintop: 20px" :gutter="16">
      <el-col :span="24">
        <el-card shadow="never" style="width: 1000px">
          <el-row :gutter="16">
            <el-col :span="8">
              <span>{{$t('public.ApplicationName')+'：'}}{{ dataArray.appName }}</span>
            </el-col>
            <el-col :span="12">
              <span class="span-text">{{$t('public.ApplicationID')+'：'}}{{ dataArray.packageName }}</span>
            </el-col>
            <el-col :span="4">
              <span>{{$t('public.Version')+'：'}}{{ dataArray.versionName }}</span>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="24" style="margin-top: 20px">
        <el-card shadow="never" style="width: 1000px">
          <span v-if="scanScore >= 0">{{ $t('AppManage.Appdetection.Shoptest.Detectedsuccess') }}</span>
          <span v-if="scanScore == -2">{{ $t('AppManage.Appdetection.Shoptest.Notdetected') }}</span>
          <span v-if="scanScore == -1">{{ $t('AppManage.Appdetection.Shoptest.Detecting') }}</span>
          <span v-if="scanScore >= 0" style="margin-left: 620px;">
            <el-button @click="jiance" size="small" type="primary" style="width: 100px;">{{ $t('AppManage.Appdetection.Shoptest.Retest') }}</el-button>
          </span>
          <span v-if="scanScore == -2" style="margin-left: 600px;">
            <el-button @click="jiance" size="small" type="primary" style="width: 100px;">{{ $t('AppManage.Appdetection.Shoptest.Startdetection') }}</el-button>
          </span>
          <span v-if="scanScore == -1" style="margin-left: 532px;">
            <el-button @click="jiance" :disabled="true" size="small" type="primary" style="width: 100px;">{{ $t('AppManage.Appdetection.Shoptest.Retest') }}</el-button>
          </span>
        </el-card>
      </el-col>
    </el-row>
    <div v-if="scanScore >= 0">
      <!-- PDF -->
      <el-row style="marginleft: 350px" :gutter="16">
        <el-col style="margin-top: 60px;width:200px;">
          <div id="Ding" style="width: 150px; height: 150px"></div>
        </el-col>
        <el-col style="width:220px;">
          <div id="main" style="width: 425px; height: 300px; margin-left: 25px"></div>
        </el-col>
        <el-col style="width:200px;">
          <p>
            <el-button size="small" type="primary" style="width: 100px; margin-top: 60px; margin-left: 370px" @click="report2pdf">{{ $t('AppManage.Appdetection.Shoptest.PDFreport') }}</el-button>
          </p>
          <p>
            <el-button size="small" type="primary" style="width: 100px; margin-top: 20px; margin-left: 370px" @click="report2doc">{{ $t('AppManage.Appdetection.Shoptest.DOCreport') }}</el-button>
          </p>
        </el-col>
      </el-row>
      <el-row style="marginleft: 350px;" :gutter="16">
        <el-col :span="24">
          <div v-if="this.picChart.high>0" style="width: 1000px; height: 80px; line-height: 80px">
            <b>{{$t('AppManage.Appdetection.Shoptest.Detectionresult')}}<span style="color: #E84646;">{{$t('AppManage.Appdetection.Shoptest.Highriskstate')}}</span>，{{$t('AppManage.Appdetection.Shoptest.Codetection')}}{{this.picChart.high+this.picChart.low+this.picChart.medium}}个漏洞</b>
          </div>
          <div v-else-if="this.picChart.medium>0" style="width: 1000px; height: 80px; line-height: 80px">
            <b>{{$t('AppManage.Appdetection.Shoptest.Detectionresult')}}<span style="color: #f59331">{{$t('AppManage.Appdetection.Shoptest.Mediumcritical')}}</span>，{{$t('AppManage.Appdetection.Shoptest.Codetection')}}{{this.picChart.high+this.picChart.low+this.picChart.medium}}个漏洞</b>
          </div>
          <div v-else-if="this.picChart.low>0" style="width: 1000px; height: 80px; line-height: 80px">
            <b>{{$t('AppManage.Appdetection.Shoptest.Detectionresult')}}<span style="color: #62B2D7">{{$t('AppManage.Appdetection.Shoptest.Lowcritical')}}</span>，{{$t('AppManage.Appdetection.Shoptest.Codetection')}}{{this.picChart.high+this.picChart.low+this.picChart.medium}}个漏洞</b>
          </div>
          <div v-else style="width: 1000px; height: 80px; line-height: 80px">
            <b>{{$t('AppManage.Appdetection.Shoptest.Detectionresult')}}<span style="color: #008000">{{$t('AppManage.Appdetection.Shoptest.Safestate')}}</span>，{{$t('AppManage.Appdetection.Shoptest.Codetection')}}{{this.picChart.high+this.picChart.low+this.picChart.medium}}个漏洞</b>
          </div>
        </el-col>
      </el-row>
      <el-row style="marginleft: 350px;" :gutter="16">
        <el-col :span="24">
          <el-card class="box-card" style="width: 1000px">
            <div slot="header" class="clearfix" style="height: 10px; color: #1e89e5">
              <span> <b>{{ $t('DeviceManage.Device.Basicinformation') }}</b> </span>
            </div>
            <div class="card">
              <div class="text item" style="height: 40px">
                <span>{{$t('public.ApplicationName')}}：{{ dataArray.appName }}</span>
              </div>
              <div class="text item" style="height: 40px">
                <span>{{$t('public.Size')}}：{{ dataArray.appSizeStr }}</span>
              </div>
              <div class="text item" style="height: 40px">
                <span>{{$t('public.Version')}}：{{ dataArray.versionName }}</span>
              </div>
              <!-- <div class="text item" style="height: 40px">
                <span v-if="queryObj.report">应用加固：{{ queryObj.report.protect_company }}</span>
              </div> -->
            </div>
            <div class="card" style="position: absolute; top: 67px; right: 150px">
              <div class="text item" style="height: 40px">
                <span>{{$t('AppManage.Appdetection.Shoptest.CreationTime')}}：{{ queryObj.created_date | dateformat}}</span>
              </div>
              <div class="text item" style="height: 40px">
                <span>{{$t('public.ApplicationID')}}：{{ dataArray.packageName }}</span>
              </div>
              <div class="text item" style="height: 40px">
                <span>MD5：{{ queryObj.md5sum }}</span>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <!-- 资源文件 -->
      <el-row style="marginleft: 350px; margintop: 30px;margin-top:20px;" :gutter="16">
        <el-col>
          <el-card class="box-card" style="width: 1000px">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5"> <b>{{$t('AppManage.Appdetection.Shoptest.URLaddress')}}</b> </span>
            </div>
            <div class="item" style="max-height:500px;overflow:auto;">
              <template>
                <template v-if="zyFile.length > 0">
                  <p style="height:23px;border-bottom:1px solid #EBEEF5;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;" :title="item" v-for="item,index in zyFile" :key="index">
                    {{item?item:""}}
                  </p>
                </template>
                <template v-else> {{$t('AppManage.Appdetection.Shoptest.Noinfor')}} </template>
              </template>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <!-- E-mail信息 -->
      <el-row style="marginleft: 350px; margintop: 30px;margin-top:20px;" :gutter="16">
        <el-col>
          <el-card class="box-card" style="width: 1000px">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5"> <b>E-mail信息</b> </span>
            </div>
            <div class="item">
              <template>
                <template v-if="email.length > 1">
                  <p style="height:23px;border-bottom:1px solid #EBEEF5;" v-for="item in email" :key="item">
                    {{item}}
                  </p>
                </template>
                <template v-else> {{$t('AppManage.Appdetection.Shoptest.Noinfor')}} </template>
              </template>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <!-- 权限信息 permissions-->
      <el-row style="marginleft: 350px; margintop: 30px; heigth: 200px; overflow: auto;margin-top:20px;" :gutter="16">
        <el-col>
          <el-card class="box-card" style="width: 998px">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5"> <b>{{$t('AppManage.Appdetection.Shoptest.Permissioninfor')}}</b> </span>
            </div>
            <div class="itemSpan" style="margin-top: -20px">
              <div style="width:100%;">
                <span style="width:320px;">{{$t('AppManage.Appdetection.Shoptest.Limitsof')}}</span><span style="width: 260px">{{$t('AppManage.Appdetection.Shoptest.Description')}}</span><span style="width:220px;">{{$t('AppManage.Appdetection.Shoptest.Jeopardize')}}</span>
                <span style="width:120px;">{{$t('AppManage.Appdetection.Shoptest.Dangerclass')}}</span>
              </div>
              <div style="max-height:346px;overflow:auto;">
                <template>
                  <template v-if="permissions.length > 0">
                    <div v-for="(newitem, newindex) in permissions" :key="newindex">
                      <span style="width:310px;font-size:14px;">{{ permissions[newindex][0] }}</span>
                      <span style="width:250px;font-size:14px;margin:0 10px;">{{ permissions[newindex][2] }}</span>
                      <span :title="permissions[newindex][3]" class="text-permi" style="width: 225px;font-size:14px;">
                        <template>{{ permissions[newindex][3] }}</template>
                      </span>
                      <span style="width:120px;color: #f59331;font-size:14px;position: relative;" v-if="permissions[newindex][1] == 0"><a style="position: absolute;bottom:0px;">{{$t('AppManage.Appdetection.Shoptest.Unknown')}}</a></span>
                      <span style="width:120px;color: green;font-size:14px;position: relative;" v-if="permissions[newindex][1] == 1"><a style="position: absolute;bottom:0px;">{{$t('AppManage.Appdetection.Shoptest.Secure')}}</a></span>
                      <span style="width:120px;color: #e84646;font-size:14px;position: relative;" v-if="permissions[newindex][1] == 2"><a style="position: absolute;bottom:0px;">{{$t('AppManage.Appdetection.Shoptest.Danger')}}</a></span>
                    </div>
                  </template>
                  <template v-else> {{$t('AppManage.Appdetection.Shoptest.Noinfor')}} </template>
                </template>
              </div>
            </div>
            <!-- <div class="item" style="margin-top:20px;">
            <div>
              未检测到任何信息
            </div>
          </div> -->
          </el-card>
        </el-col>
      </el-row>
      <!-- 敏感行为检测 -->
      <el-row style="marginleft: 350px; margintop: 30px;margin-top:20px;" :gutter="16">
        <el-col>
          <el-card class="box-card" style="width: 1000px">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5"> <b>{{$t('AppManage.Appdetection.Shoptest.Sensitivebe')}}</b> </span>
            </div>
            <div class="item">
              <div>
                <span style="color: #6f7982">
                  <b>{{$t('AppManage.Appdetection.Shoptest.Description')}}：</b>
                </span>
                {{$t('AppManage.Appdetection.Shoptest.Appall')}}
              </div>
            </div>

            <!-- <div class="item">
            <div>
              <span style="color:#6f7982">
              <b>{{$t('AppManage.Appdetection.Shoptest.Solution')}}：</b>
              </span>
              开发者审核包含敏感行为的函数调用，确保其使用是必要且限制于授权用户
            </div>
          </div> -->
          </el-card>
        </el-col>
      </el-row>
      <!-- sensitive_list 赋值 -->
      <el-row style="marginleft: 350px; margintop: -25px" :gutter="16">
        <el-col>
          <div style="max-height:346px;overflow:auto;">
            <el-table :cell-style="{padding:'3px 0px'}" :header-row-style="{height:'30px'}" :data="sensitive_list" border style="width: 1000px">
              <el-table-column prop="name" :label="$t('AppManage.Appdetection.Shoptest.Name')" width="550">
              </el-table-column>
              <el-table-column prop="level" :label="$t('AppManage.Appdetection.Shoptest.Dangerclass')" width="240">
                <template v-slot="{ row }">
                  <template v-if="row.level == $t('AppManage.Appdetection.Shoptest.Secure')"><span style="color: green"> {{$t('AppManage.Appdetection.Shoptest.Secure')}}</span>
                  </template>
                  <template v-if="row.level == $t('AppManage.Appdetection.Shoptest.Mediumvu')">
                    <span style="color: #e84646">{{ $t('AppManage.Appdetection.Shoptest.Moderately') }}</span>
                    <!-- <span style="color: #f59331">中危漏洞</span> -->
                  </template>
                  <template v-if="row.level == $t('AppManage.Appdetection.Shoptest.Lowriskvu')">
                    <span style="color: #e84646">{{ $t('AppManage.Appdetection.Shoptest.lowrisk') }}</span>
                    <!-- <span style="color: 49c6ce">低危漏洞</span> -->
                  </template>
                  <template v-if="row.level == $t('AppManage.Appdetection.Shoptest.Highriskvu')">
                    <span style="color: #e84646">{{ $t('AppManage.Appdetection.Shoptest.Highrisk') }}</span>
                  </template>
                </template>
              </el-table-column>
              <el-table-column :label="$t('AppManage.Appdetection.Shoptest.Detailedinfor')">
                <template v-slot="{ row }">
                  <span style="color:#d38a08;cursor: pointer;" @click="behavior(row)" type="primary">{{ $t('AppManage.Appdetection.Shoptest.Detailedinfor') }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-col>
      </el-row>
      <!-- 静态检测漏洞 -->
      <el-row style="marginleft: 350px; margintop: 30px;margin-top:20px;" :gutter="16">
        <el-col>
          <el-card class="box-card" style="width: 1000px">
            <div class="item">
              <div>
                <span style="color: #1e89e5">
                  <b>{{$t('AppManage.Appdetection.Shoptest.Detectingvulner')}}</b>
                </span>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-row style="marginleft: 350px; margintop: -25px" :gutter="16">
        <el-col>
          <div style="max-height:346px;overflow:auto;width:1000px;">
            <el-table :cell-style="{padding:'3px 0px'}" :header-row-style="{height:'30px'}" :data="static_list" border style="width: 1000px">
              <el-table-column prop="name" :label="$t('AppManage.Appdetection.Shoptest.VulnerabilityName')" width="550">
                <template v-slot="{ row }">
                  <template v-if="row.level !== $t('AppManage.Appdetection.Shoptest.Secure')">
                    {{row.name}}
                  </template>
                </template>
              </el-table-column>
              <el-table-column prop="level" :label="$t('AppManage.Appdetection.Shoptest.VulnerabilityLevel')" width="250">
                <template v-slot="{ row }">
                  <template v-if="row.level == $t('AppManage.Appdetection.Shoptest.Mediumvu')">
                    <span style="color: #ff8d00">{{ $t('AppManage.Appdetection.Shoptest.Moderately') }}</span>
                  </template>
                  <template v-if="row.level == $t('AppManage.Appdetection.Shoptest.Lowriskvu')">
                    <span style="color: #00c7ce">{{ $t('AppManage.Appdetection.Shoptest.lowrisk') }}</span>
                  </template>
                  <template v-if="row.level == $t('AppManage.Appdetection.Shoptest.Highriskvu')">
                    <span style="color: #fc2f3d">{{ $t('AppManage.Appdetection.Shoptest.Highrisk') }}</span>
                  </template>
                </template>
              </el-table-column>
              <el-table-column :label="$t('AppManage.Appdetection.Shoptest.Detailedinfor')">
                <template v-slot="{ row }">
                  <span style="color:#d38a08;cursor: pointer;" @click="louDong(row)" type="primary">{{ $t('AppManage.Appdetection.Shoptest.Detailedinfor') }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-col>
      </el-row>
      <div style="margintop:100px;height:100px;"></div>
    </div>
    <!-- 静态检测漏洞 -->
    <Eldialog @handleClose="handleClose" :num="'470px'" :title="title" :dialogVisible="dialogVisible" :width="'1000px'">
      <el-row style="width: 100%">
        <el-col :span="24">
          <el-card class="box-card" style="width: 100%">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5">
                <b>{{$t('AppManage.Appdetection.Shoptest.Vulnerabilityprofile')}}</b>
              </span>
            </div>
            <div class="item">
              <div v-html="detailData.summary"></div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="24">
          <el-card class="box-card" style="width: 100%">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5">
                <b>{{$t('AppManage.Appdetection.Shoptest.Details')}}</b>
              </span>
            </div>
            <div class="item">
              <div v-html="detailData.payload"></div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="24">
          <el-card class="box-card" style="width: 100%">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5"> <b>{{$t('AppManage.Appdetection.Shoptest.Solution')}}</b> </span>
            </div>
            <div class="item">
              <div v-html="detailData.solution"></div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </Eldialog>
    <!-- 敏感行为检测 -->
    <Eldialog @handleClose="handleClose" :width="'1000px'" :num="'470px'" :title="title" :dialogVisible="dialogBehavior">
      <el-row style="width: 100%">
        <el-col :span="24">
          <el-card class="box-card" style="width: 100%">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5">
                <b>{{$t('AppManage.Appdetection.Shoptest.Behaviorprofile')}}</b>
              </span>
            </div>
            <div class="item">
              <div v-html="detailData.summary"></div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="24">
          <el-card class="box-card" style="width: 100%">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5">
                <b>{{$t('AppManage.Appdetection.Shoptest.Details')}}</b>
              </span>
            </div>
            <div class="item">
              <div v-html="detailData.payload"></div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="24">
          <el-card class="box-card" style="width: 100%">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5"> <b>{{$t('AppManage.Appdetection.Shoptest.Solution')}}</b> </span>
            </div>
            <div class="item">
              <div v-html="detailData.solution"></div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </Eldialog>
    <!-- 敏感内容检测 -->
    <Eldialog @handleClose="handleClose" :width="'1000px'" :num="'470px'" :title="title" :dialogVisible="dialogBehaviors">
      <el-row style="width: 100%">
        <el-col :span="24">
          <el-card class="box-card" style="width: 100%">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5">
                <b>{{$t('AppManage.Appdetection.Shoptest.Sensitivecontent')}}</b>
              </span>
            </div>
            <div class="item">
              <div v-html="detailData.summary"></div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="24">
          <el-card class="box-card" style="width: 100%">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5">
                <b>{{$t('AppManage.Appdetection.Shoptest.Details')}}</b>
              </span>
            </div>
            <div class="item">
              <div v-html="detailData.payload"></div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="24">
          <el-card class="box-card" style="width: 100%">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5"> <b>{{$t('AppManage.Appdetection.Shoptest.Solution')}}</b> </span>
            </div>
            <div class="item">
              <div v-html="detailData.solution"></div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </Eldialog>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import Eldialog from '../../../components/elDialog/index.vue'
export default {
  props: {
    sidd: {
      type: Number,
      default: null
    },
    scanScore: {
      type: Number,
      default: null
    },
    dataArray: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    Eldialog
  },
  data() {
    return {
      a: {},
      b: {},
      c: {},
      d: {},
      SiDa: {},
      color: '',
      title: '获取手机信息',
      dictData: {},
      fourData: {}, //四大组件
      fourDataKey: {}, //四大组件的key值
      securityCount: 0, //安全数据出事为0
      detailData: {}, //详情的唐匡对象
      tableData: [
        {
          category: 1,
          name: '恶意扣费'
        },
        {
          category: 1,
          name: '信息窃取'
        },
        {
          category: 1,
          name: '远程控制'
        },
        {
          category: 1,
          name: '恶意传播'
        },
        {
          category: 1,
          name: '资费消耗'
        },
        {
          category: 1,
          name: '系统破坏'
        },
        {
          category: 1,
          name: '诱骗诈骗'
        },
        {
          category: 1,
          name: '流氓行为'
        }
      ],
      email: [], //email 信息
      zyFile: [], //资源文件
      mydata: [],
      ipAdress: [], //IP地址
      signbook: [], //签名证书
      picChart: [],
      static_list: [],
      permissions: [],// 权限信息
      sensitive_list: [],
      tabBass: false,
      dialogFour: false, //四大组件
      dialogFour2: false, //四大组件2
      dialogFour3: false, //四大组件3
      dialogFour4: false, //四大组件4
      dialogVisible: false, //静态敏感弹框
      dialogBehavior: false, //敏感行为弹框
      dialogBehaviors:false,
      score: [{ value: 0, name: 'Engine' }],//环形分数
      titlefour: 'Andriod四大组件：Activity',
      titlefour2: 'Andriod四大组件：Service',
      titlefour3: 'Andriod四大组件：Provider',
      titlefour4: 'Andriod四大组件：Receiver',
      titleStatus: this.scanScore == 0 ? '检测完成' : '正在检测应用请稍后...',
      queryObj: {
        cert: '[</br>[</br>  Version: V1</br>  Subject: C=US, O=Android, CN=Android Debug</br>  Signature Algorithm: SHA1withRSA, OID = 1.2.840.113549.1.1.5</br></br>  Key:  </br>  Validity: [From: Wed May 03 15:22:34 CST 2017,</br>               To: Fri Apr 26 15:22:34 CST 2047]</br>  Issuer: C=US, O=Android, CN=Android Debug</br>  SerialNumber: [    01]</br></br>]</br>  Algorithm: [SHA1withRSA]</br>  Signature:</br>0000: 22 60 1E CC 07 CA 37 A6   A2 38 23 89 E8 9E F6 7C  &quot;`....7..8#.....</br>0010: 01 AF B9 2D 65 DF 87 FF   7C D3 B3 CD 87 46 51 0C  ...-e........FQ.</br>0020: 27 B7 1A 06 81 75 8E 88   76 3D 99 DA 39 56 1C 65  &#39;....u..v=..9V.e</br>0030: F5 09 7F 91 AD 7D 05 95   E4 F7 93 AB E5 7C FA DC  ................</br>0040: D5 AC CA C9 8B ED 55 73   47 2D E5 C3 56 B5 40 ED  ......UsG-..V.@.</br>0050: EA B0 DD 13 AC 4C C9 8A   05 02 DA 0D D4 A4 CA 8D  .....L..........</br>0060: 90 EC 61 7F A1 28 5A A3   40 1C BE 37 3C C1 D0 A6  ..a..(Z.@..7&lt;...</br>0070: F3 A9 B5 C1 ED E5 F3 21   77 47 BA 95 97 DF F9 87  .......!wG......</br></br>]</br>'
      },
    }
  },
  mounted() {
    this.Ring() //环形饼图
    this.pieChart() //饼图
    this.tableList()
  },
  methods: {
    iRowStyle: function ({ row, rowIndex }) {//表格行高
      return 'height:30px'
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return 'height:30px'
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return 'padding:0px'
    },
    fourCompent() {// 四大组件
      this.dialogFour = true
    },
    fourCompent2() {// 四大组件2
      this.dialogFour2 = true
    },
    fourCompent3() {// 四大组件3
      this.dialogFour3 = true
    },
    fourCompent4() {// 四大组件3
      this.dialogFour4 = true
    },
    behavior(val) { // 敏感行为检测弹框
      this.title = val.name + this.$t('AppManage.Appdetection.Shoptest.Detailedinfor')
      this.dialogBehavior = true
      this.detailList(val) //------------*****??????
    },
    behaviors(val) {
      this.title = val.name + this.$t('AppManage.Appdetection.Shoptest.Detailedinfor')
      this.dialogBehaviors = true
      this.detailList(val) //------------*****??????
    },
    louDong(val) {// 静态敏感弹框
      this.title = val.name + this.$t('AppManage.Appdetection.Shoptest.Detailedinfor')
      this.dialogVisible = true
      this.detailList(val)
    },
    async jiance() {// 重新检测
      this.titleStatus = this.$t('AppManage.Appdetection.Betesting')
      let params = {
        ids: [this.sidd + '']
      }
      const res = await this.$axios.post(
        '/httpServe/entAppScan/send',
        params,
        true
      )
      if (res.code == 200) {
        this.tableList()
        this.$emit('haldesotr')
      }
    },
    async report2pdf() {// 下载PDF
      let params = {
        "reportType": 1,
        ids: [this.sidd]
      }
      const res = await this.$axios.post(
        '/httpServe/entApp/report2pdf',
        params,
        true
      )
      var param = {
        path: res.data
      }
      const s = await this.$axios.post(
        '/httpServe/sysFile/fileDown',
        param,
        true
      )
      await this.$axios.downloadFile(s.data)
    },
    async report2doc() {// 下载doc
      let params = {
        "reportType": 1,
        ids: [this.sidd]
      }
      const res = await this.$axios.post(
        '/httpServe/entApp/report2doc',
        params,
        true
      )
      var param = {
        path: res.data
      }
      const s = await this.$axios.post(
        '/httpServe/sysFile/fileDown',
        param,
        true
      )
      await this.$axios.downloadFile(s.data)
    },
    async detailList(arr) {// 应用检测
      let params = {
        type: '1',
        id: this.sidd + '',
        vulid: arr ? arr.vulid : '',
        taskid: this.taskId
      }
      console.log(params)
      const res = await this.$axios.post(
        '/httpServe/entAppScan/detail',
        params,
        true
      )
      this.$nextTick(() => {
        let aa = res.data.payload
        if (aa.substr(0, 1) == '[') {
          let str = aa.replace(/[\s+\'\"\[\]]/g, '')
          let arr = str.replace(/\,/g, '</br>')
          this.detailData = {
            payload: arr,
            solution: res.data.solution,
            summary: res.data.summary
          }
          return
        }
        let payloads = aa.replace(/\\n/g, '</br>')
        this.detailData = {
          payload: payloads,
          solution: res.data.solution,
          summary: res.data.summary
        }
      })
    },
    async tableList() {
      let params = {
        type: '1',
        id: this.sidd + ''
      }
      const res = await this.$axios.post(
        '/httpServe/entAppScan/scanReport',
        params,
        true
      )
      console.log(res)
      if (res.data) {
        this.queryObj = res.data //最外层的数据
        this.tableClean = res.data
        this.taskId = res.data.report.task_id //task_id 应用检测详情传的 // 四大组件
        let certsList = res.data.report.cert
          .replace(/[\s+\'\"\[\]\u]/g, '')
          .split(',')
        this.signbook = certsList // 签名证书
        if (res.data.report.urls_list.length > 0) {
          this.zyFile = res.data.report.urls_list
            .replace(/[\s+\'\"\[\]\u]/g, '')
            .split(',') // 资源文件urls_list
        }
        if (res.data.report.ips_list.length > 0) {
          this.ipAdress = res.data.report.ips_list
            .replace(/[\s+\'\"\[\]\u]/g, '')
            .split(',') //ip地址
        }
        this.permissions = res.data.report.permissions //权限信息
        console.log(this.permissions)
        if (res.data.report.emails_list.length > 0) {
          this.email = res.data.report.emails_list
            .replace(/[\s+\'\"\[\]\u]/g, '')
            .split(',') //email信息
        }
        let dataStatusSen = JSON.parse(JSON.stringify(res.data.report.iosvul_list)) //敏感行为检测 this.iosvul_list
        for (let index = 0; index < dataStatusSen.length; index++) {
          let contFond = false
          for (
            let newIndex = 0;
            newIndex < res.data.report.ios_info_vuls.length;
            newIndex++
          ) {
            if (
              dataStatusSen[index].id == res.data.report.ios_info_vuls[newIndex]
            ) {
              if (dataStatusSen[index].level == 1) {
                dataStatusSen[index].level = this.$t('AppManage.Appdetection.Shoptest.Highriskvu')
              } else if (dataStatusSen[index].level == 2) {
                dataStatusSen[index].level = this.$t('AppManage.Appdetection.Shoptest.Mediumvu')
              } else if (dataStatusSen[index].level == 3) {
                dataStatusSen[index].level = this.$t('AppManage.Appdetection.Shoptest.Lowriskvu')
              }
              contFond = true
              break
            }
          }
          if (!contFond) {
            dataStatusSen[index].level = this.$t('AppManage.Appdetection.Shoptest.Secure')
          }
        }
        dataStatusSen.forEach((item, index) => {
          if (item.level == this.$t('AppManage.Appdetection.Shoptest.Highriskvu')) {
            this.sensitive_list.push(item)
          }
        })
        dataStatusSen.forEach((item, index) => {
          if (item.level == this.$t('AppManage.Appdetection.Shoptest.Mediumvu')) {
            this.sensitive_list.push(item)
          }
        })
        dataStatusSen.forEach((item, index) => {
          if (item.level == this.$t('AppManage.Appdetection.Shoptest.Lowriskvu')) {
            this.sensitive_list.push(item)
          }
        })
        let dataStaticLevel = JSON.parse(
          JSON.stringify(res.data.report.iosvul_list)
        ) //静态检测漏洞
        let codeList = res.data.report.ios_code_vuls.concat(
          res.data.report.ios_app_vuls
        )
        for (let index = 0; index < dataStaticLevel.length; index++) {
          let contFond = false
          for (let newIndex = 0; newIndex < codeList.length; newIndex++) {
            if (dataStaticLevel[index].id == codeList[newIndex]) {
              if (dataStaticLevel[index].level == 1) {
                dataStaticLevel[index].level = this.$t('AppManage.Appdetection.Shoptest.Highriskvu')
              } else if (dataStaticLevel[index].level == 2) {
                dataStaticLevel[index].level = this.$t('AppManage.Appdetection.Shoptest.Mediumvu')
              } else if (dataStaticLevel[index].level == 3) {
                dataStaticLevel[index].level = this.$t('AppManage.Appdetection.Shoptest.Lowriskvu')
              }
              contFond = true
              break
            }
          }
          if (!contFond) {
            dataStaticLevel[index].level = this.$t('AppManage.Appdetection.Shoptest.Secure')
          }
        }
        dataStaticLevel.forEach((item) => {
          if (item.level == this.$t('AppManage.Appdetection.Shoptest.Highriskvu')) {
            this.static_list.push(item)
          }
        })
        dataStaticLevel.forEach((item, index) => {
          if (item.level == this.$t('AppManage.Appdetection.Shoptest.Mediumvu')) {
            this.static_list.push(item)
          }
        })
        dataStaticLevel.forEach((item, index) => {
          if (item.level == this.$t('AppManage.Appdetection.Shoptest.Lowriskvu')) {
            this.static_list.push(item)
          }
        })
        var sz1 = res.data.report.sensitive_list //敏感
        var sb1 = res.data.report.sensitive_vuls //敏感
        var sz2 = res.data.report.static_list //静态
        var sb2 = res.data.report.static_vuls //静态
        let szids2 = sz2.map((item) => item.id)// 找到静态对象id
        let jingtai = szids2.filter((item) => sb2.indexOf(item) == -1).length
        let szids = sz1.map((item) => item.id)// 找到敏感对象的id
        let mingan = szids.filter((item) => sb1.indexOf(item) == -1).length
        let sum = 0
        sum = jingtai + mingan
        this.securityCount = sum// 定义全局变量
        let score1 = []// 环形数据
        score1.push({
          value: res.data.report.score
        })
        this.score = score1
        this.picChart = res.data.report.risk
        if (this.picChart.high > 0) {
          this.color = '#E84646'
        } else if (this.picChart.high == 0 && this.picChart.medium > 0) {
          this.color = '#f59331'
        } else if (
          this.picChart.high == 0 &&
          this.picChart.low > 0 &&
          this.picChart.medium == 0
        ) {
          this.color = '#62B2D7'
        } else {
          this.color = '#008000'
        }
        this.Ring(this.color)
        this.pieChart()
      }
    },
    handleClose() {
      this.dialogVisible = false
      this.dialogBehavior = false
      this.dialogBehaviors = false
      this.dialogFour = false //四大组件
      this.dialogFour2 = false //四大组件2
      this.dialogFour3 = false //四大组件3
      this.dialogFour4 = false //四大组件4
    },
    Ring(colorS) {//环形饼图
      var chartDom = document.getElementById('Ding')
      var myChart = echarts.init(chartDom)
      var option
      option = {
        tooltip: {
          trigger: 'item'
        },
        color: colorS,
        series: [
          {
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
              normal: {
                show: true,
                position: 'center',
                color: '#000',
                fontSize: 16,
                fontWeight: 'bold',
                formatter: '{c}分' // {b}:数据名； {c}：数据值； {d}：百分比，可以自定义显示内容，
              }
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: this.score
          }
        ]
      }
      option && myChart.setOption(option)
    },
    pieChart() {
      // let labels = ['低危漏洞','高危漏洞','中危漏洞','安全']
      // let value =[]
      //饼图
      var chartDom = document.getElementById('main')
      var myChart = echarts.init(chartDom)
      var option
      option = {
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            label: {
              normal: {
                formatter: '{b} : ({d}%)'
              }
            },
            type: 'pie',
            radius: '50%',
            data: [
              {
                value: this.picChart.high,
                name: this.$t('AppManage.Appdetection.Shoptest.Highriskvu'),
                itemStyle: { color: '#fc2f3d' }
              },
              {
                value: this.picChart.medium,
                name: this.$t('AppManage.Appdetection.Shoptest.Mediumvu'),
                itemStyle: { color: '#ff8d00' }
              },
              {
                value: this.picChart.low,
                name: this.$t('AppManage.Appdetection.Shoptest.Lowriskvu'),
                itemStyle: { color: '#00c7ce' }
              }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }

      option && myChart.setOption(option)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
.text {
  font-size: 14px;
  margin-left: 40px;
}
.box-card {
  position: relative;
}
.itemSpan {
  span {
    display: inline-block;
    width: 250px;
  }
}
.text-permi {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
::v-deep .el-card__body {
  overflow: auto;
}
.span-text {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
