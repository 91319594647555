<template>
  <div>
    <el-row :gutter="16">
      <el-col :span="24">
        <el-card shadow="never" style="width: 1000px">
          <span style="margin-left: 50px">{{$t('public.ApplicationName')}}：{{ androidData.appName }}</span>
          <span style="margin-left: 100px">{{$t('public.ApplicationID')}}：{{ androidData.packageName }}</span>
          <span style="margin-left: 140px">{{$t('public.Version')}}：{{ androidData.versionName }}</span>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="16">
      <el-col v-if="androidData.osTypeId == 20" :span="24">
        <el-checkbox v-model="use">{{$t('AppManage.AppManagePage.SecurityPage.SecurityReinforcement')}}</el-checkbox>
      </el-col>
      <el-col v-if="!checkedFlag" :span="24">
        <el-checkbox :disabled="SandboxDis" v-model="checked2">{{$t('AppManage.AppManagePage.SecurityPage.SafetySandbox')}}</el-checkbox>
      </el-col>
    </el-row>
    <el-row style="padding: 0 60px" v-if="checkedFlag && androidData.osTypeId == 20" :gutter="16">
      <el-col :span="24">
        <el-checkbox :disabled="true" v-model="dex">{{$t('AppManage.AppManagePage.SecurityPage.DexProtection')}}</el-checkbox>
      </el-col>
      <el-col :span="24">
        <el-checkbox v-model="sowen">{{$t('AppManage.AppManagePage.SecurityPage.SOfile')}}</el-checkbox>
      </el-col>
      <el-col :span="24" class="customer-tables" style="height: 152px; overflow: auto">
        <el-table :cell-style="{padding:'3px 0px'}" :header-row-style="{height:'30px'}" v-if="sowenList.length>5" stripe ref="soTable" :data="sowenList" tooltip-effect="dark" style="width: 1000px" @selection-change="handleSelectionChange1">
          <el-table-column type="selection" :selectable="selectEnable" width="55">
          </el-table-column>
          <el-table-column :label="$t('AppManage.AppManagePage.SecurityPage.FileName')" prop="name"></el-table-column>
        </el-table>
        <el-table :cell-style="{padding:'3px 0px'}" :header-row-style="{height:'30px'}" v-else stripe ref="soTable" :data="sowenList" tooltip-effect="dark" style="width: 1000px" @selection-change="handleSelectionChange1">
          <el-table-column type="selection" :selectable="selectEnable" width="55">
          </el-table-column>
          <el-table-column :label="$t('AppManage.AppManagePage.SecurityPage.FileName')" prop="name"></el-table-column>
        </el-table>
        <el-checkbox class="checked-box" @change="selectAll" :disabled="!sowen"></el-checkbox>
      </el-col>
      <el-col :span="24">
        <el-checkbox v-model="sqliteProtect">{{$t('AppManage.AppManagePage.SecurityPage.Sqlite')}}</el-checkbox>
      </el-col>
      <el-col :span="24">
        <el-checkbox v-model="sharedPerferenceProtect">{{$t('AppManage.AppManagePage.SecurityPage.SharePreference')}}</el-checkbox>
      </el-col>
      <el-col :span="24">
        <el-checkbox v-model="manifest">{{$t('AppManage.AppManagePage.SecurityPage.Manifest')}}</el-checkbox>
      </el-col>
      <el-col :span="24">
        <el-checkbox v-model="memprotect">{{$t('AppManage.AppManagePage.SecurityPage.MemoryDump')}}</el-checkbox>
      </el-col>
      <el-col :span="24">
        <el-checkbox v-model="integrity">{{$t('AppManage.AppManagePage.SecurityPage.IntegrityCheck')}}</el-checkbox>
      </el-col>
      <el-col :span="24">
        <el-checkbox v-model="checksign_type">{{$t('AppManage.AppManagePage.SecurityPage.SignatureVerification')}}</el-checkbox>
      </el-col>
      <el-col :span="24">
        <el-radio v-model="radio" :disabled="!checksign_type" label="1">{{$t('AppManage.AppManagePage.SecurityPage.SigningCertificate')}}</el-radio>
      </el-col>
      <el-col :span="24">
        <el-table :cell-style="{padding:'3px 0px'}" :header-row-style="{height:'30px'}" v-if="tableData1.length>5" class="customer-table" stripe ref="multipleTable" :data="tableData1" tooltip-effect="dark" style="width: 1000px;height: 183px; overflow: auto" @selection-change="handleSelectionChange">
          <el-table-column width="55">
            <template v-slot="{ row }">
              <el-radio-group @change="changeEvent1(row)" v-model="zhengshu">
                <el-radio :label="row.id" :disabled="radio != 1">{{
                  ""
                }}</el-radio>
              </el-radio-group>
            </template>
          </el-table-column>
          <el-table-column :label="$t('AppManage.AppManagePage.SecurityPage.Certificate')" prop="cerName" />
        </el-table>
        <el-table :cell-style="{padding:'3px 0px'}" :header-row-style="{height:'30px'}" v-else class="customer-table" stripe ref="multipleTable" :data="tableData1" tooltip-effect="dark" style="width: 1000px" @selection-change="handleSelectionChange">
          <el-table-column width="55">
            <template v-slot="{ row }">
              <el-radio-group @change="changeEvent1(row)" v-model="zhengshu">
                <el-radio :label="row.id" :disabled="radio != 1">{{
                  ""
                }}</el-radio>
              </el-radio-group>
            </template>
          </el-table-column>
          <el-table-column :label="$t('AppManage.AppManagePage.SecurityPage.Certificate')" prop="cerName" />
        </el-table>
      </el-col>
      <el-col :span="24">
        <el-radio v-model="radio" :disabled="!zsjy" label="2">{{$t('AppManage.AppManagePage.SecurityPage.CertificateOne')}}</el-radio>
      </el-col>
      <el-col :span="24">
        <div>
          <el-col style="line-height: 40px;font-size:14px;" :span="1.5">{{$t('AppManage.AppManagePage.SecurityPage.PlaceCertificateOne')}}:</el-col>
          <el-col :span="4">
            <el-input :disabled="radio == 1" size="mini" v-model="input" :placeholder="$t('AppManage.AppManagePage.SecurityPage.PlaceCertificateOne')"></el-input>
          </el-col>
        </div>
      </el-col>
    </el-row>
    <!-- 安全沙箱内容 -->
    <!-- {{checkedFlag}} -- {{SandboxDis}} -->
    <el-row v-if="checkedFlag" :gutter="16">
      <el-col v-if="androidData.osTypeId == 10 || androidData.osTypeId == 20" :span="24">
        <el-checkbox :disabled="SandboxDis" v-model="checked2">{{$t('AppManage.AppManagePage.SecurityPage.SafetySandbox')}}</el-checkbox>
      </el-col>
    </el-row>
    <el-row style="padding: 0 60px" :gutter="16" v-if="checkedFlag2">
      <el-col :span="24">
        <el-checkbox v-if="androidData.osTypeId == 20" :disabled="SandboxDis" v-model="sandbox.hiddenIcon">{{$t('AppManage.AppManagePage.SecurityPage.SecurityDomain')}} </el-checkbox>
        <el-tooltip placement="right" effect="light">
          <div slot="content" style="color: #807e7e">
            {{$t('AppManage.AppManagePage.SecurityPage.HideDesktopIcon')}}
          </div>
          <img v-if="androidData.osTypeId == 20" src="@/images/wen1.png" alt="" srcset="" style="padding-left: 8px;width: 24px;" />
        </el-tooltip>
        <!-- <img v-if="androidData.osTypeId == 20" style="width: 15px; margin-left: 5px" src="@/images/wen1.png" alt="" title="隐藏桌面图标" /> -->
      </el-col>
      <!-- 沙箱策略 -->
      <el-col :span="24">
        <el-checkbox @change="SandboxS" :disabled="SandboxDis" v-model="shaxiang">{{$t('AppManage.AppManagePage.SecurityPage.SandboxStrategy')}}</el-checkbox>
      </el-col>
      <el-col :span="24">
        <el-table :cell-style="{padding:'3px 0px'}" :header-row-style="{height:'30px'}" v-if="dictData.length>5" class="customer-table" stripe ref="multipleTable" :data="dictData" tooltip-effect="dark" style="width: 1000px;height: 152px; overflow: auto" @selection-change="handleSelectionChange">
          <el-table-column width="55">
            <template v-slot="{ row }">
              <el-radio-group @change="changeEvent(row)" v-model="radio1">
                <el-radio :disabled="!shaxiangFlag||SandboxDis" :label="row.id">{{
                  ""
                }}</el-radio>
              </el-radio-group>
            </template>
          </el-table-column>
          <el-table-column :label="$t('AppManage.AppManagePage.SecurityPage.PolicyName')" prop="name" />
        </el-table>
        <el-table :cell-style="{padding:'3px 0px'}" :header-row-style="{height:'30px'}" v-else class="customer-table" stripe ref="multipleTable" :data="dictData" tooltip-effect="dark" style="width: 1000px" @selection-change="handleSelectionChange">
          <el-table-column width="55">
            <template v-slot="{ row }">
              <el-radio-group @change="changeEvent(row)" v-model="radio1">
                <el-radio :disabled="!shaxiangFlag" :label="row.id">{{
                  ""
                }}</el-radio>
              </el-radio-group>
            </template>
          </el-table-column>
          <el-table-column :label="$t('AppManage.AppManagePage.SecurityPage.PolicyName')" prop="name" />
        </el-table>
      </el-col>
    </el-row>
    <!-- 签名证书 -->
    <el-row :gutter="16">
      <el-col :span="24">
        <el-checkbox v-model="checked">{{$t('AppManage.AppManagePage.SecurityPage.SigningCertificate')}}</el-checkbox>
      </el-col>
      <el-col style="padding: 0 60px;" v-if="checked" :span="24">
        <el-table :cell-style="{padding:'3px 0px'}" :header-row-style="{height:'30px'}" v-if="tableData1.length>5" class="customer-table" stripe ref="multipleTable" :data="tableData1" tooltip-effect="dark" style="width: 1000px;height: 183px; overflow: auto;" @selection-change="handleSelectionChange">
          <el-table-column width="55">
            <template v-slot="{ row }">
              <el-radio-group @change="changeEvent2(row)" v-model="zhengshu1">
                <el-radio :disabled="!zhensghuFlag" :label="row.id">{{
                  ""
                }}</el-radio>
              </el-radio-group>
            </template>
          </el-table-column>
          <el-table-column :label="$t('AppManage.AppManagePage.SecurityPage.Certificate')" prop="cerName" />
        </el-table>
        <el-table :cell-style="{padding:'3px 0px'}" :header-row-style="{height:'30px'}" v-else class="customer-table" stripe ref="multipleTable" :data="tableData1" tooltip-effect="dark" style="width: 1000px;" @selection-change="handleSelectionChange">
          <el-table-column width="55">
            <template v-slot="{ row }">
              <el-radio-group @change="changeEvent2(row)" v-model="zhengshu1">
                <el-radio :disabled="!zhensghuFlag" :label="row.id">{{
                  ""
                }}</el-radio>
              </el-radio-group>
            </template>
          </el-table-column>
          <el-table-column :label="$t('AppManage.AppManagePage.SecurityPage.Certificate')" prop="cerName" />
        </el-table>
      </el-col>
    </el-row>
    <el-row :gutter="16" style="margin-bottom: 100px">
      <el-col :span="24">
        <el-button type="primary" @click="reinforce" :disabled="
            checkedFlag == false && checked2 == false && checked == false || androidData.purgeStatus == 2
          " :loading="loading">{{$t('AppManage.AppManagePage.SecurityPage.ReinforceBtn')}}</el-button>
        <!-- <el-button style="margin-left:10px;" v-if="androidData.purgeStatus == 1" @click="downZheng" type="primary">下载加固证明</el-button> -->
      </el-col>
    </el-row>
    <dialog-info :dialogInfo="visibaelFlag" @handleClose="handleClose" :titleInfo="titleInfo" :width="'240px'" />
  </div>
</template>
<script>
import dialogInfo from '@/components/promptMessage/index'
export default {
  components: { dialogInfo },

  props: {
    sidd: {
      type: Number,
      default: null
    },
    androidData: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      SandboxDis:false,
      AudioNodeList: {},
      loading: false,
      visibaelFlag: false,
      titleInfo: '',
      input: '',
      use: false,
      dex: !this.checkedFlag,
      soList: [],
      sqliteProtect: false,
      sharedPerferenceProtect: false,
      manifest: false,
      memprotect: false,
      integrity: false,
      checksign_type: false,
      sha1: '',
      sandbox: {
        use: false,
        hiddenIcon: false,
        configId: null
      },
      checked2: false,
      sowen: false,
      shaxiang: false,
      checked: false,
      zhengshu: '',
      zhengshu1: '',
      zsList: {},
      zsList1: {},
      radio: '',
      radio1: '',
      radio2: '',
      clName: '',
      dictData: [],
      tableData: [],
      tableData1: [],
      tableData2: [],
      sowenList: [],
      multipleSelection: []
    }
  },

  computed: {
    checkedFlag() {
      return this.use
    },
    checkedFlag2() {
      return this.checked2
    },
    shaxiangFlag() {
      return this.shaxiang
    },
    zhensghuFlag() {
      return this.checked
    },
    zsjy() {
      return this.checksign_type
    }
  },

  watch: {
    sowenList: {
      handler(newVal) {
        newVal.forEach((item, index) => {
          this.soList.forEach((newItem) => {
            if (item.name == newItem) {
              this.$nextTick(() => {
                //newVal是新的表格数据，[8] 8是表格数据要默认选中的索引
                this.$refs.soTable.toggleRowSelection(newVal[index], true)
              })
            }
          })
        })
      },
      immediate: true
    }
  },

  created() {
    this.tableList() //策略名称列表
    this.soLists() //so文件列表
    this.getDataInfo()
    console.log(this.androidData,'this.androidData');
    let androidRadio = this.androidData
    this.AudioNodeList = JSON.parse(this.androidData.purgeInfo)
    console.log(this.AudioNodeList, '11111')
    if (this.AudioNodeList) {
      this.use = this.AudioNodeList.purge.use == 1 ? true : false
      this.dex = this.AudioNodeList.purge.dex == 1 ? true : false
      this.sowen = this.AudioNodeList.purge.soList.length > 0 ? true : false
      this.soList = this.AudioNodeList.purge.soList
      this.sqliteProtect =
        this.AudioNodeList.purge.sqliteProtect == 1 ? true : false
      this.sharedPerferenceProtect =
        this.AudioNodeList.purge.sharedPerferenceProtect == 1 ? true : false
      this.manifest = this.AudioNodeList.purge.manifest == 1 ? true : false
      this.memprotect = this.AudioNodeList.purge.memprotect == 1 ? true : false
      this.integrity = this.AudioNodeList.purge.integrity == 1 ? true : false
      this.checksign_type =
        this.AudioNodeList.purge.checksign_type.use == 1 ? true : false
      this.checked = this.AudioNodeList.cer.use == 1 ? true : false
      this.zhengshu = this.AudioNodeList.purge.checksign_type.cerId
      this.zhengshu1 = this.AudioNodeList.cer.cerId
      this.radio = this.zhengshu ? '1' : ''
      // this.radio1 = this.AudioNodeList.sandBox.configId
      // this.shaxiang = this.radio1 ? true : false
      this.input = this.AudioNodeList.purge.checksign_type.sha1
      this.checked2 = this.AudioNodeList.sandBox.use == 1 ? true : false
      this.sandbox.hiddenIcon =
        this.AudioNodeList.sandBox.hiddenIcon == 1 ? true : false
      this.zsList1.id = this.AudioNodeList.cer.cerId
        ? this.AudioNodeList.cer.cerId
        : ''
      this.zsList.id = this.AudioNodeList.purge.checksign_type.cerId
        ? this.AudioNodeList.purge.checksign_type.cerId
        : ''
      this.sandbox.configId = this.AudioNodeList.sandBox.configId
        ? this.AudioNodeList.sandBox.configId
        : '';
    }
    if(this.androidData.apppermissionid){
      this.sandbox.configId = this.androidData.apppermissionid
      this.checked2 = true
      this.shaxiang=true;
      console.log(this.androidData.apppermissionid,'11111');
      this.radio1=this.androidData.apppermissionid+''
    }
    if(this.androidData.purgeStatus == 9 ||this.androidData.purgeStatus == 10) {
      this.SandboxDis = true
    }else {
      this.SandboxDis = false
    }
  },

  methods: {
    SandboxS(val) {
      this.sandbox.configId = ''
      if(!val) {
        this.radio1 = ''
      }
    },
    async downZheng() {
      var param = {
        path: this.androidData.reportPath
      }
      const res = await this.$axios.post(
        '/httpServe/sysFile/fileDown',
        param,
        true
      )
      // console.log(res);
      await this.$axios.downloadFile(res.data)
    },
    // 立即加固
    async reinforce() {
      if (!this.checksign_type) {
        this.radio = ''
        this.zsList.id = ''
      }
      if (!this.checked) {
        this.zsList1.id = ''
      }
      if (!this.checked2) {
        this.sandbox.configId = ''
      }
      let params = {
        ids: [this.sidd],
        name: this.androidData.appName,
        packagename: this.androidData.packageName,
        versionname: this.androidData.versionName,
        path: this.androidData.path,
        ostypeid: this.androidData.osTypeId + '',
        purgeStatus:this.androidData.purgeStatus,
        purge: {
          use: this.use ? 1 : 0,
          dex: this.dex ? 1 : 0,
          soList: this.soList,
          sqliteProtect: this.sqliteProtect ? 1 : 0,
          sharedPerferenceProtect: this.sharedPerferenceProtect ? 1 : 0,
          manifest: this.manifest ? 1 : 0,
          memprotect: this.memprotect ? 1 : 0,
          integrity: this.integrity ? 1 : 0,
          checksign_type: {
            use: this.checksign_type ? 1 : 0,
            cerId: this.zsList.id,
            sha1: this.input
          }
        },
        sandbox: {
          use: this.checked2 ? 1 : 0,
          hiddenIcon: this.sandbox.hiddenIcon ? 1 : 0,
          configId: this.sandbox.configId ? this.sandbox.configId : ''
        },
        cer: {
          use: this.checked ? 1 : 0,
          cerId: this.zsList1.id
        }
      }
      // console.log(params)
      if (this.sowen && this.soList.length <= 0) {
        this.$message({
          message: '请选择SO文件',
          type: 'error',
          offset: 100
        })
        return
      }
      if (this.shaxiang && !this.radio1) {
        this.$message({
          message: '请选择策略名称',
          type: 'error',
          offset: 100
        })
        return
      }
      if (this.checksign_type && this.radio == '') {
        this.$message({
              message: "请选择签名证书",
              type: "error",
              offset: 100,
        });
        return
      }
      if (this.radio == '1' && !this.zhengshu) {
        this.$message({
          message: '请选择签名证书',
          type: 'error',
          offset: 100
        })
        return
      }
      if (this.checked && !this.zhengshu1) {
        // console.log(1)
        this.$message({
          message: '请选择签名证书',
          type: 'error',
          offset: 100
        })
        return
      }
      if (params.purge.use != 1) {
        if (this.shaxiang && !params.cer.cerId) {
          this.$message({
            message: '请选择签名证书',
            type: 'error',
            offset: 100
          })
          return
        }
      }
      // if(this.use&&params.purge.checksign_type.cerId &&params.cer.cerId){
      //   console.log(1)
      //   this.titleInfo = "请选择签名证书";
      //   this.visibaelFlag = true;
      //   return
      // }
      if (this.checksign_type && this.radio == '1' && this.checked) {
        // console.log(params.purge.checksign_type.cerId == params.cer.cerId)
        if (params.purge.checksign_type.cerId != params.cer.cerId) {
          this.$message({
            message: '签名证书选择不一致',
            type: 'error',
            offset: 100
          })
          return
        }
      }
      this.$message({
        message: '正在加固请稍后',
        type: 'success',
        offset: 100
      })
      this.loading = true
      const res = await this.$axios.post(
        '/httpServe/purgeApp/reinforce',
        params,
        true
      )
      if (res.code == 200) {
        this.loading = false
        this.$emit('haldesotr')
      }
    },
    handleClose() {
      this.visibaelFlag = false
    },
    // 查询证书列表
    async getDataInfo() {
      let params = {
        currentPage: 1,
        pageSize: 9999,
        // orderColume: '',
        // orderRule: '',
        cerName: ''
      }
      const res = await this.$axios.post(
        '/httpServe/cer/getDataInfo',
        params,
        true
      )
      // console.log(this.androidData.osTypeId);
      this.tableData1 = res.data.content.filter(
        (item) => item.osType == this.androidData.osTypeId
      )
    },
    // 查询so文件列表
    async soLists() {
      let params = {
        ids: [this.sidd],
        currentPage: 1,
        pageSize: 9999
      }
      const res = await this.$axios.post(
        '/httpServe/purgeApp/selectSo',
        params,
        true
      )
      console.log(res)
      if (res.data == '[]') {
        this.sowenList = []
      } else {
        let aa = res.data.substr(1)
        let bb = aa.substr(0, aa.length - 1)
        let newdata = bb.split(',')
        this.sowenList = newdata
        let data = []
        this.sowenList.forEach((item) => {
          data.push({
            name: item
          })
        })
        this.sowenList = data
        console.log(this.sowenList)
      }
    },
    // 策略名称
    async tableList() {
      let params = {}
      const res = await this.$axios.post(
        '/httpServe/entAppPermission/selectNameList',
        params,
        true
      )
      this.dictData = res.data.filter(
        (item) => item.osTypeId == this.androidData.osTypeId
      )
    },
    changeEvent(row) {
      // console.log(row);
      this.sandbox.configId = row.id
      this.clName = row.name
    },
    changeEvent1(row) {
      // console.log(row);
      this.zsList = row
    },
    changeEvent2(row) {
      this.zsList1 = row
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // so选中文件
    handleSelectionChange1(val) {
      this.multipleSelection = val
      let data = []
      this.multipleSelection.forEach((item) => {
        data.push(item.name)
      })
      this.soList = data
    },
    selectAll() {
      this.$refs.soTable.toggleAllSelection()
    },
    // so文件
    selectEnable() {
      if (!this.sowen) {
        return false
      } else {
        return true // 不禁用
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
::v-deep .el-row {
  margin-left: 350px !important;
  margin-top: 5px !important;
}
::v-deep .el-col-24 {
  margin: 0px 0 10px 0;
}
::v-deep .el-table__header-wrapper .el-checkbox {
  //找到表头那一行，然后把里面的复选框隐藏掉
  display: none;
}
::v-deep .el-table .el-table__cell {
  padding: 0;
}
.customer-tables {
  position: relative;
}
.checked-box {
  position: absolute;
  left: 15px;
  top: 5px;
}
</style>